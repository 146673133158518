
import { defineComponent, ref, watchEffect } from 'vue';
import TableContentLoader from './TableContentLoader.vue';
import { useStore } from 'vuex';
import CustomerDetailTableContentLoader from '../../modules/customer/customers/components/partials/CustomerDetailTableContentLoader.vue';

export default defineComponent({
    props: {
        columns: { type: Object, required: true },
        rows: { type: Array, required: true },
        show_empty: { type: Boolean, default: false },
        pagination: {
            type: Object,
            default() {
                return {};
            }
        },
        padding: { type: Number },
        textAlignment: { type: String, default: '' },
        module: { type: String, default: '' },
        width: { type: String, default: '' },
        loading: { type: Boolean, default: false },
        sorting: { type: Boolean, default: false }
    },
    components: { TableContentLoader, CustomerDetailTableContentLoader },
    setup() {
        const type = ref(true);
        const state = ref(false);
        const store = useStore();

        const changeType = (types: boolean) => {
            type.value = !types;
            state.value = true;
        };

        watchEffect(() => {
            const searchData = store.getters.getSearchData;
            if (Object.entries(searchData).length == 0) {
                state.value = false;
            }
        });

        return {
            type,
            changeType,
            state
        };
    }
});
